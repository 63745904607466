import { useQuery } from "react-query";

import { queryConfig, queryClient } from "../../_libs/react-query";

import {
  fetchAppoinmentRequestCollection,
  fetchAppoinmentRequestList,
  fetchClinicAppoinmentRequestCollection,
} from "./_services";

export const QUERY_KEY_NAME = "AppointmentRequestsQuery";
export const QUERY_KEY_LIST = "AppointmentRequestsQueryList";

export const queryAppointmentRequests = ({ dpid }) => {
  let searchQueryString = ``;
  if (dpid !== null) {
    searchQueryString = searchQueryString + `dpid=${dpid}`;
  }
  return fetchAppoinmentRequestCollection(searchQueryString);
};

export const refetchAppointmentRequests = () => {
  queryClient.refetchQueries([QUERY_KEY_LIST], {dpid: null});
};

export const queryAppointmentRequestList = ({ dpid }) => {
  let searchQueryString = ``;
  if (dpid !== null) {
    searchQueryString = searchQueryString + `dpid=${dpid}`;
  }
  return fetchAppoinmentRequestList(searchQueryString);
};
export const queryClinicAppointmentRequestList = ({ dpid }) => {
  let searchQueryString = ``;
  if (dpid !== null) {
    searchQueryString = searchQueryString + `dpid=${dpid}`;
  }
  return fetchClinicAppoinmentRequestCollection(searchQueryString);
};

const newQueryConfig = {
  ...queryConfig,
  queries: {
    ...queryConfig.queries,
    refetchOnMount: false,
  },
  keepPreviousData: true,
};

export const GetAppointmentRequestsQuery = ({ dpid = null }) => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [QUERY_KEY_NAME, { dpid }],
    queryFn: () => queryAppointmentRequests({ dpid }),
  });
};

export const GetAppointmentRequestListQuery = ({ dpid = null }) => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [QUERY_KEY_LIST, { dpid }],
    queryFn: () => queryAppointmentRequestList({ dpid }),
  });
};
export const GetClinicAppointmentRequestListQuery = ({ dpid = null }) => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [QUERY_KEY_LIST, { dpid }],
    queryFn: () => queryClinicAppointmentRequestList({ dpid }),
  });
};
