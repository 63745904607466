import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { startCase } from "lodash";
//import Logo from "../../../images/icons/logo";
import { LogoImage } from "js/_components/Logo/logo";
import { getFullNameString } from "js/_helpers";
import ChevronDownIcon from "images/icons/ChevronDownIcon";

import { useAuth } from "js/_libs/auth";
import { FaChevronDown } from "react-icons/fa";
import LogoutIcon from "images/icons/LogoutIcon";
import SettingIcon from "images/icons/SettingIcon";
import { URLS } from "js/_config";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const Topbar = ({ ...props }) => {
  const {
    logout,
    user: { user },
  } = useAuth();

  const displayName = getFullNameString(
    "{givenName} {familyName}",
    user.first_name,
    user.last_name
  );
  const history = useHistory();

  const handleClickOnSetting = () => {
    history.push(URLS.appointmentsSettings);
  };
  const handleClickOnLogout = () => {
    logout();
  };
  return (
    <Flex
      w={"full"}
      justify={"space-between"}
      pl={[12, 12]}
      py={[8, 8]}
      pr={[24, 24]}
      {...props}
    >
      <Flex align={"center"}>
        <Link to={"/appointments"}>
          <LogoImage />
        </Link>
      </Flex>
      <Flex align={"center"}>
        <Flex cursor={"pointer"}>
          <Menu 
            background={"#FFF"} 
              placement={'bottom-end'}
            >
            <MenuButton>
              <Flex align={"center"}>
                <Flex>
                  <Avatar
                    borderRadius="full"
                    boxSize={47}
                    border={"3px solid #FFFFFF"}
                    filter={"drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"}
                    src={user?.avatar}
                    name={displayName}
                    bg={"brandLight.primary"}
                    color={"white"}
                    letterSpacing={"1.1px"}
                    mr={6}
                  />
                </Flex>
                <Flex direction={"column"} justify={"center"}>
                  <Heading fontSize={14} fontWeight={500}>
                    {displayName}
                  </Heading>
                  <Text textAlign={"left"} fontSize={12} color={"darkGray"}>
                    {startCase((user?.user_role || "").toLowerCase())}
                  </Text>
                </Flex>
                <ChevronDownIcon
                  ml={'20px'}
                />
              </Flex>
            </MenuButton>

            <MenuList
              minW={"150px"}
              background={"white"}
              zIndex={50}
              mt={"10px"}
              borderRadius={"10px"}
              px={'10px'}
              py={'10px'}
              boxShadow={"rgba(0, 0, 0, 0.4) 0px 30px 90px;"}
            >
              <MenuItem
                // icon={<LogoutIcon color={"#7B8887"} />}
                fontSize={"12px"}
                fontWeight={400}
                color={"#7B8887"}
                p={'10px'}
                onClick={() => {
                  handleClickOnSetting();
                }}
              > <SettingIcon color={"#7B8887"} mr={"10px"} />
                <Text>
                  <FormattedMessage 
                    id={'sidebar.navItem.settings'}
                    defaultMessage={'Settings'}
                  />
                  </Text>
              </MenuItem>
              <MenuItem
                // icon={<LogoutIcon color={"#7B8887"} />}
                fontSize={"12px"}
                p={'10px'}
                fontWeight={400}
                color={"#7B8887"}
                onClick={() => {
                  handleClickOnLogout();
                }}
              >
                <LogoutIcon color={"#7B8887"} mr={"10px"} />  
                <Text>
                  <FormattedMessage 
                    id={'sidebar.navItem.logout.label'}
                    defaultMessage={'Settings'}
                  />
                  </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
