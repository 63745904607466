import React from "react";
import { Router, Route } from "react-router-dom";
import { useSelector, Provider } from "react-redux";
import "babel-polyfill";
import "@fontsource/noto-sans-kr/900.css";
import "@fontsource/noto-sans-kr/700.css";
import "@fontsource/noto-sans-kr/500.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import { ChakraProvider, Center, Spinner, Box, Flex } from "@chakra-ui/react";
import { history, store } from "../_helpers";

import Logo from "images/icons/logo";
import IntlProvider from "../_components/IntlProvider/IntlProvider";
import PubnubProvider from "../_components/PubnubProvider/PubnubProvider";
import { PrivateRoute } from "../_components";
import NavBar from "../_components/NavMenu/NavBar";
import { OverarchingDialog } from "../OverarchingDialog";
import { createSelector } from "reselect";

import { GOOGLE_CLIENT_ID, AVAILABLE_LANGUAGES } from "../_config";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "../_libs/auth";

import { lazyImport } from "../_helpers/lazyImport";
import { MainPageRoutes } from "../_routes/MainPageRoutes";
import { LoginPage } from "../LoginPage";
import { LoginAppPage } from "../LoginPage/LoginAppPage";
import { LoginAdminPage } from "../LoginPage/LoginAdminPage";

import { AdminPageRoutes } from "../AdminPage/routes";
import ScheduledMessagesRoutes from "../ScheduledMessages/routes";
import AppointmentsRoutes from "../Appointments/routes";
import TreatmentPlansRoutes from "../TreatmentPlans/routes";

import { TestPage } from "../TestPage";

import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../_libs/react-query";

import "moment/locale/zh-cn";
import "moment/locale/th";
import "moment/min/locales";

import { settingsActions } from "../_actions";
import { settingsService } from "../_services";
import Notifications from "react-notification-system-redux";
import { theme } from "../../css/theme";

import { useLocale } from "../_hooks/useLocale";
import storage from "../_helpers/storage";

import "react-datepicker/dist/react-datepicker.css";

import "../../css/bootstrap.min.css";
import "../../css/index.css";
import "../../css/home.style.css";
import "../../css/admin.style.css";
import { LineLinkAccount } from "js/LineLinkAccount/LineLinkAccount";
import { BillingModal } from "js/Popups/BillingPopup";
import moment from "moment";
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  //...
};
const { NewConsultRequestPage } = lazyImport(
  () => import("../NewConsultRequestPage"),
  "NewConsultRequestPage"
);
const { PatientPage } = lazyImport(
  () => import("../PatientPage"),
  "PatientPage"
);
const { PatientsList } = lazyImport(
  () => import("../Patients/PatientsList"),
  "PatientsList"
);
const { TreatmentplanPage } = lazyImport(
  () => import("../TreatmentplanPage/TreatmentplanPage"),
  "TreatmentplanPage"
);
const { PhotoAssessmentDetail } = lazyImport(
  () => import("../PhotoAssessments/PhotoAssessmentDetail"),
  "PhotoAssessmentDetail"
);
const { OrderDetail } = lazyImport(
  () => import("../Orders/OrderDetail"),
  "OrderDetail"
);
const { UserProfilePage } = lazyImport(
  () => import("../UserProfilePage"),
  "UserProfilePage"
);
const { PasswordForgotPage } = lazyImport(
  () => import("../PasswordForgotPage"),
  "PasswordForgotPage"
);
const { PasswordResetPage } = lazyImport(
  () => import("../PasswordResetPage"),
  "PasswordResetPage"
);

//const analytics = getAnalytics();
//logEvent(analytics, 'notification_received');

//const app = initializeApp(firebaseConfig);

const notifStyle = {
  NotificationIem: {
    DefaultStyle: {
      margin: "30px 50px",
      padding: "40px 20px",
    },
  },
};

const getNotificationsSlice = (state) => state.notifications;
const getNotifications = createSelector(
  [getNotificationsSlice],
  (notifications) => {
    return notifications;
  }
);

export const App = () => {
  const { setLocale, locale } = useLocale();
  const initLang = React.useRef(null);
  const notifications = useSelector(getNotifications);

  React.useEffect(() => {
    return () => {
      // Cleanup
    };
  }, []);

  React.useEffect(() => {
    moment.locale(locale);
    if (initLang.current == null && setLocale != null) {
      const language =
        storage.getLocale() ||
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
      console.log("storage.getLocale() ", storage.getLocale());

      // Split locales with a region code
      //const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      let currentLanguage = language || "en";
      console.log("CURRENT LANG:", currentLanguage);
      if (
        AVAILABLE_LANGUAGES.map((lang, ind) => lang.value).indexOf(
          currentLanguage
        ) === -1
      ) {
        currentLanguage = "en";
      }
      console.log("currentLanguage", currentLanguage);

      initLang.current = currentLanguage;
      setLocale(currentLanguage);
    }
  }, [setLocale, initLang]);

  return (
    <IntlProvider onError={() => void 0}>
      <ChakraProvider theme={theme}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <PubnubProvider>
                {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
                <Router history={history}>
                  <Box
                    id={"AppContainer"}
                    fontFamily={"Proxima Nova, Georgia, sans-serif"}
                    height={"100vh"}
                    overflowY={"hidden"}
                  >
                    <Notifications
                      notifications={notifications}
                      style={notifStyle}
                      store={store}
                    />
                    <BillingModal />
                    <OverarchingDialog />
                    <React.Suspense
                      fallback={
                        <Box h="100vh">
                          <Box
                            pl={[12, 12]}
                            py={[8, 8]}
                            pr={[24, 24]}
                          >
                            <Flex align={"center"}>
                              <Logo />
                            </Flex>
                          </Box>
                          <Box w={'full'} mx={4} borderBottom="1px" borderBottomColor={'#00948233'}/>
                          <Center
                            h="calc(100vh - 72px)"
                            w="100vw"
                            backgroundColor={"transparent"}
                          >
                            <Spinner
                              size={"xl"}
                              thickness="5px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="primary.500"
                            />
                          </Center>
                        </Box>
                      }
                    >
                      <Route exact path="/test" component={TestPage} />
                      <Route
                        exact
                        path="/admin/login"
                        component={LoginAdminPage}
                      />
                      <Route
                        path="/admin/login/:login_type"
                        component={LoginAdminPage}
                      />
                      <Route exact path="/login" component={LoginAppPage} />
                      <Route
                        path="/reset/:uid/:token"
                        component={LoginAppPage}
                      />
                      <Route
                        path="/login/:login_type"
                        component={LoginAppPage}
                      />
                      <Route path="/forgot" component={LoginAppPage} />
                      <Route
                        exact
                        path="/reset/:uid/:token"
                        component={PasswordResetPage}
                      />
                      {/*<Route
                        exact
                        path="/forgot"
                        component={PasswordForgotPage}
                      />*/}
                      <Route
                        exact
                        path="/linelink/:uid/:token"
                        component={LineLinkAccount}
                      />
                      <Route exact path="/linelink" component={LoginAppPage} />
                      <Route
                        exact
                        path="/treatment/:id"
                        component={TreatmentplanPage}
                      />
                      <PrivateRoute
                        exact
                        path="/requests/new"
                        component={NewConsultRequestPage}
                      />
                      <PrivateRoute
                        exact
                        path="/patient/:patient_id/:tab"
                        component={PatientPage}
                      />
                      <PrivateRoute
                        exact
                        path="/patient/:patient_id"
                        component={PatientPage}
                      />
                      <PrivateRoute
                        exact
                        path="/patients"
                        component={PatientsList}
                      />
                      <PrivateRoute
                        exact
                        path="/photoassessments/:request_id"
                        component={PhotoAssessmentDetail}
                      />
                      <PrivateRoute
                        exact
                        path="/orders/:orderId"
                        component={OrderDetail}
                      />
                      <PrivateRoute
                        exact
                        path="/profile"
                        component={UserProfilePage}
                      />
                      <Route
                        path="/treatmentplans"
                        component={TreatmentPlansRoutes}
                      />
                      <Route
                        path="/appointments/"
                        component={AppointmentsRoutes}
                      />
                      <Route
                        path="/scheduled/"
                        component={ScheduledMessagesRoutes}
                      />
                      <Route path="/bd/admin/" component={AdminPageRoutes} />
                      <Route path="/" component={MainPageRoutes} />
                    </React.Suspense>
                  </Box>
                </Router>
              </PubnubProvider>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ChakraProvider>
    </IntlProvider>
  );
};
