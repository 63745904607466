import { useQuery } from 'react-query';

import { queryConfig, queryClient } from 'js/_libs/react-query';

import { fetchAppointmentRequestCount } from './_services';

export const QUERY_KEY_NAME = 'AppointmentRequestCount';

export const getAppointmentRequestCount = () => {
  return fetchAppointmentRequestCount()
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
};

export const refetchAppointmentRequestCount = () => {
  queryClient.refetchQueries([QUERY_KEY_NAME]);
};

export const GetAppointmentRequestCount = () => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [QUERY_KEY_NAME],
    queryFn: () => getAppointmentRequestCount(),
  });
}; 
