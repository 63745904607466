const window_location = window.location;

var BASE_API_URL = process.env.REACT_APP_API_URL;
var BASE_URL = window.location.origin; //process.env.REACT_APP_BASE_URL;

var GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const REMINDER_TIME = "10:00";

const TOKBOX_KEY = "45999732";
const PAGE_SIZE = 15;
const MAIN_SCROLL_CSS_CLASS_NAME = "custom-scroll";
const MOMENT_DATE_FORMAT = "L";
const MOMENT_LONG_DATE_FORMAT = "lll";
const API_DATE_FORMAT = "YYYY-MM-DD";
const API_DATETIME_FORMAT = "YYYY-MM-DD HH:mm";
const NOTIFICATION_POLLING_INTERVAL = 60 * 1000; // in milliseconds
const NOTIFICATION_POLLING_HISTORYLENGTH = 15;
const GENDERS = [
  { value: "male", label: "MALE", intlKey: "male" },
  { value: "female", label: "FEMALE", intlKey: "female" },
  { value: "other", label: "OTHER", intlKey: "other" },
];

const JAW_CHOICES = [
  { value: "BOTH", label: "BOTH", intlKey: "both" },
  { value: "UPPER", label: "UPPER", intlKey: "upper" },
  { value: "LOWER", label: "LOWER", intlKey: "lower" },
];

const STATUS_CHOICES = [
  { value: "CANCELED", label: "canceled", intlKey: "canceled" },
  { value: "INIT", label: "initialized", intlKey: "initialized" },
  { value: "CONFIRMED", label: "confirmed", intlKey: "confirmed" },
  { value: "PRODUCTION", label: "production", intlKey: "production" },
  { value: "SHIPPED", label: "shipped", intlKey: "shipped" },
  { value: "COMPLETED", label: "completed", intlKey: "completed" },
];

const AVAILABLE_WHOLESALERS = [
  { value: "46", label: "Beforedent Thailand" },
  { value: "47", label: "Thailand Wholesaler" },
];

const AVAILABLE_COUNTRIES = ["TH", "JP", "KR"];

const AVAILABLE_CLINIC_TYPES = [
  { value: "scanning_only", label: "Scanning Only", intlKey: "scanning_only" },
  { value: "provider", label: "Provider", intlKey: "provider" },
];

const AVAILABLE_LANGUAGES = [
  { value: "en", label: "English" },
  { value: "th", label: "ภาษาไทย" },
  { value: "ja", label: "日本語" },
];

const APPOINTMENTTYPES_COLOR_SCHEME = {
  "3D_SCAN": "blue",
  CHECKUP: "red",
};

var currYear = new Date().getFullYear();

const URLS = {
  alineHome: "https://beforedent.com",
  home: "/",
  login: "/login",
  forgotPassword: "/forgot",
  newConsultRequest: "/requests/new",
  shopAligners: "https://beforedent.com/th",
  shopAlignersImpression: "https://beforedent.com/th",
  appointmentsCalendarRequests: "/appointments/requests",
  appointmentsCalendar: "/appointments/",
  // appointmentsDashboard: "/appointments/",
  appointmentsDentists: "/appointments/dentists",
  appointmentsPatients: "/appointments/patients",
  appointmentsAccounts: "/appointments/accounts",
  appointmentsSettings: "/appointments/settings",
  appointmentsBroadcast: "/appointments/broadcast",
  consultRequests: "/requests",
  photoAssessmentPage: "/photoassessments/{requestId}",
  patientPage: "/patient/{patientId}",
  patientPageTabImaging: "/patient/{patientId}/imaging",
  patientPageImageSet: "/patient/{patientId}/imaging?imgId={imageSetId}",
  patientPageTabReports: "/patient/{patientId}/reports",
  patientPageReport: "/patient/{patientId}/reports?reqId={requestId}",
  patientPageReportMessage:
    "/patient/{patientId}/reports?reqId={requestId}&msgId={messageId}",
  patientTreatmentViewer: "/treatment/{id}",
  userSettingsPage: "/profile",
  dashboard: "/appointments/dashboard",
};

const API_URLS = {
  getVersioning: { method: "GET", url: `${BASE_API_URL}/d/version` },
  login: { method: "POST", url: `${BASE_API_URL}/authen/login/` },
  lineCheck: {
    method: "GET",
    url: `${BASE_API_URL}/d/line/dental_practice/{clinicId}`,
  },
  lineLink: {
    method: "POST",
    url: `${BASE_API_URL}/d/line/connect/{clinicId}/{token}`,
  },
  requestLoginCode: {
    method: "POST",
    url: `${BASE_API_URL}/auth/code/request`,
  },
  loginCode: { method: "POST", url: `${BASE_API_URL}/auth/login/code` },
  loginSocial: { method: "POST", url: `${BASE_API_URL}/auth/login/social/` },
  logout: { method: "POST", url: `${BASE_API_URL}/authen/logout/` },
  getChannels: { method: "GET", url: `${BASE_API_URL}/msg/pn/channels` },
  getSelectOptions: { method: "GET", url: `${BASE_API_URL}/select_options/` },
  forgotPassword: {
    method: "POST",
    url: `${BASE_API_URL}/authen/password/reset/`,
  },
  resetPassword: {
    method: "POST",
    url: `${BASE_API_URL}/authen/password/reset/confirm/`,
  },
  changePassword: {
    method: "POST",
    url: `${BASE_API_URL}/authen/password/change/`,
  },
  getOrdersSelectOptions: {
    method: "GET",
    url: `${BASE_API_URL}/ord/select_options/`,
  },
  productsSearch: {
    method: "POST",
    url: `${BASE_API_URL}/ord/products/search`,
  },
  updateSettings: { method: "POST", url: `${BASE_API_URL}/d/user/settings` },
  getConfig: { method: "GET", url: `${BASE_API_URL}/d/config` },
  getUserinfo: { method: "GET", url: `${BASE_API_URL}/d/user/profile` },
  consultRequestList: { method: "GET", url: `${BASE_API_URL}/d/requests` },
  updateOrder: { method: "POST", url: `${BASE_API_URL}/d/orders/{orderId}` },
  updateOrderStatus: {
    method: "POST",
    url: `${BASE_API_URL}/d/orders/{orderId}/status`,
  },
  updateOrderPaymentUrl: {
    method: "POST",
    url: `${BASE_API_URL}/d/orders/{orderId}/paymenturl`,
  },
  addOrderLine: {
    method: "POST",
    url: `${BASE_API_URL}/d/orders/{orderId}/lines`,
  },
  removeOrderLine: {
    method: "POST",
    url: `${BASE_API_URL}/d/orders/{orderId}/lines/{orderLineId}`,
  },
  createProduct: { method: "POST", url: `${BASE_API_URL}/ord/products` },
  getOrder: { method: "GET", url: `${BASE_API_URL}/d/orders/{orderId}` },

  //setting my account
  getMyAccounts: {
    method: "GET",
    url: `${BASE_API_URL}/sch/account`,
  },
  updateCliMyAccount: {
    method: "POST",
    url: `${BASE_API_URL}/sch/account`,
  },
  getClinicInfo: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/calendar`,
  },
  updateClinicInfo: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/calendar`,
  },
  updateAccountAvatar: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/calendar/avatar`,
  },
  getClinicSetting: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/calendar/settings`,
  },
  updateClinicSetting: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/calendar/settings`,
  },
  getLineSetting: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/lineapp/settings`,
  },
  updateLineSetting: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/lineapp/settings`,
  },
  getTagsList: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/tags`,
  },
  createTagsList: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/tags`,
  },
  updateTagsList: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/tags/{id}`,
  },
  removeTagsList: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/tags/remove`,
  },
  getPatientTagsList: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}/tags`,
  },
  addPatientTagsList: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}/tags`,
  },
  removePatientTagsList: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}/tags/remove`,
  },

  // Dashoboard Analytics
  getAnalyticsSummary: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/analytics/patients?range={month}`,
  },
  getRetentionSummary: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/analytics/retention_rate?range={range}`,
  },
  getChartData: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/analytics/appointments?range={range}&year={year}`,
  },

  // Types of the appointment
  getAppointmentTypes: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointment_types`,
  },
  createAppointmentType: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointment_types`,
  },
  updateAppointmentType: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointment_types/{typeId}`,
  },
  deleteAppointmentType: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointment_types/{typeId}/remove`,
  },

  //options for clinic
  getDentalAccountsRole: {
    method: "GET",
    url: `${BASE_API_URL}/sch/select_options/dental_account_roles`,
  },
  createBillingUrl: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/billing`,
  },
  // clinic calls
  getClinicDentistAccounts: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/dentists`,
  },
  getClinicDentistAccountsList: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/accounts`,
  },
  createClinicDentistAccounts: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/dentists`,
  },
  getClinicDentistAccountDetails: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/dentists/{dentistId}`,
  },
  updateClinicDentistAccountDetails: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/dentists/{dentistId}`,
  },

  updateClinicOperatories: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/operatories/{operatoryId}`,
  },
  createClinicOperatories: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/operatories`,
  },
  getClinicOperatories: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/operatories`,
  },
  deleteClinicOperatories: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/operatories/{operatoryId}/remove`,
  },

  getClinicAvailableHours: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/availabletimes`,
  },
  createClinicAvailableHours: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/availabletimes`,
  },
  updateClinicAvailableHours: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/availabletimes/{availableTimeId}`,
  },

  createClinicAppointment: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointments`,
  },
  getClinicAppointmentCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointments`,
  },
  getClinicAppointmentDetails: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointments/{appointmentId}`,
  },
  updateClinicAppointmentDetails: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointments/{appointmentId}`,
  },
  deleteClinicAppointment: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointments/{appointmentId}/remove`,
  },
  getPatientAppointmentCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointments?view={viewKey}&patient={patientId}&order_by={orderBy}`,
  },
  getDentistAppointmentCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointments?view={viewKey}&dentist={dentistId}&order_by={orderBy}`,
  },

  createClinicPatient: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patients`,
  },
  getClinicPatientCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/patients`,
  },
  getClinicPatientUploadCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/patientuploads/{patientId}`,
  },
  clinicPatientUploadFile: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patientuploads/{patientId}`,
  },

  getClinicPatientDetails: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}`,
  },
  updateClinicPatientDetails: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}`,
  },

  getClinicSchedules: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/schedules`,
  },
  createClinicSchedules: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/schedules`,
  },
  createClinicPatientNote: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}/notes`,
  },
  getClinicPatientNote: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/patients/{patientId}/notes`,
  },
  updateClinicPatientNote: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patientnotes/{noteId}`,
  },
  deleteClinicPatientNote: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patientnotes/{noteId}/remove`,
  },
  removeClinicPatientNoteUpload: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patientnotes/{noteId}/upload/remove`,
  },

  uploadClinicPatientNote: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/patientnotes/{noteId}/upload`,
  },

  getClinicAppointmentRequests: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointmentrequests`,
  },
  acceptClinicAppointmentRequests: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointmentrequests/{requestId}/accept`,
  },
  rejectClinicAppointmentRequests: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/appointmentrequests/{requestId}/reject`,
  },

  // Broadcast Message

  getBroadcastMessagesCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/messaging/broadcasts`,
  },
  createBroadcastMessages: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/messaging/broadcasts`,
  },
  getBroadcastFilterOption: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/messaging/broadcast_filters`,
  },

  getAppointmentCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/appointments`,
  },
  getAppointmentDetails: {
    method: "GET",
    url: `${BASE_API_URL}/sch/appointments/{appointmentId}`,
  },
  createAppointment: {
    method: "POST",
    url: `${BASE_API_URL}/sch/appointments/{appointmentId}`,
  },
  getAppointmentRequestCollection: {
    method: "GET",
    url: `${BASE_API_URL}/sch/appointmentrequests`,
  },
  getAppointmentRequestList: {
    method: "GET",
    url: `${BASE_API_URL}/sch/appointmentrequests`,
  },
  getAppointmentRequestCount: {
    method: "GET",
    url: `${BASE_API_URL}/sch/clinic/appointmentrequests/count`,
  },
  getAppointmentRequestDetails: {
    method: "GET",
    url: `${BASE_API_URL}/sch/appointmentrequests/{requestId}`,
  },
  createFromAppointmentRequest: {
    method: "POST",
    url: `${BASE_API_URL}/sch/appointmentrequests/{requestId}/createfrom`,
  },
  rejectAppointmentRequest: {
    method: "POST",
    url: `${BASE_API_URL}/sch/appointmentrequests/{requestId}/reject`,
  },
  cancelAppointmentRequest: {
    method: "POST",
    url: `${BASE_API_URL}/sch/appointmentrequests/{requestId}/cancel`,
  },
  forwardAppointmentRequest: {
    method: "POST",
    url: `${BASE_API_URL}/sch/appointmentrequests/{requestId}/forward`,
  },

  qrCodeCheckin: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/checkin/qr`,
  },
  qrCodeAppointment: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/checkin/qr/appointment`,
  },
  qrCodeWalkin: {
    method: "POST",
    url: `${BASE_API_URL}/sch/clinic/checkin/qr/appointment/walkin`,
  },
  qrCodeSend: { method: "POST", url: `${BASE_API_URL}/sch/clinic/qrcode/send` },

  ordersList: { method: "GET", url: `${BASE_API_URL}/d/orders` },
  photoAssessmentRequestList: {
    method: "GET",
    url: `${BASE_API_URL}/d/photoassessments`,
  },
  createPatient: { method: "POST", url: `${BASE_API_URL}/d/patients` },
  patientsList: { method: "GET", url: `${BASE_API_URL}/d/patients` },
  createConsultRequest: { method: "POST", url: `${BASE_API_URL}/d/requests` },
  getConsultRequestUploads: {
    method: "GET",
    url: `${BASE_API_URL}/d/requests/{requestId}/uploads`,
  },
  createConsultRequestUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/uploads`,
  },
  deleteConsultRequestUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/uploads/remove`,
  },
  changePatientUploadStatus: {
    method: "POST",
    url: `${BASE_API_URL}/d/uploads/status`,
  },
  getConsultRequestToothChart: {
    method: "GET",
    url: `${BASE_API_URL}/d/requests/{requestId}/charts/{chartType}`,
  },
  updateConsultRequestToothChart: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/charts/{chartType}`,
  },
  updateConsultRequest: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}`,
  },
  getConsultRequestDetails: {
    method: "GET",
    url: `${BASE_API_URL}/d/requests/{requestId}`,
  },
  getPhotoAssessmentDetails: {
    method: "GET",
    url: `${BASE_API_URL}/d/photoassessments/{requestId}`,
  },
  updatePhotoAssessment: {
    method: "POST",
    url: `${BASE_API_URL}/d/photoassessments/{requestId}`,
  },
  updateConsultRequestStatus: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/status`,
  },
  confirmTreatmentPlan: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/message/{messageId}/confirm`,
  },
  rejectTreatmentPlan: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/message/{messageId}/reject`,
  },
  changeRequestStatus: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/status/change`,
  },
  createConsultRequestMessage: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/messages`,
  },
  editConsultRequestMessage: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/messages/{messageId}`,
  },
  addMessageAttachment: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/messages/{messageId}/upload/`,
  },
  createMessageUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/messages/upload/`,
  },
  deleteMessageUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/requests/{requestId}/messages/upload/remove/`,
  },
  getPatientDetails: {
    method: "GET",
    url: `${BASE_API_URL}/d/patients/{patientId}`,
  },
  editPatientDetails: {
    method: "POST",
    url: `${BASE_API_URL}/d/patients/{patientId}`,
  },
  getPatientNotes: {
    method: "GET",
    url: `${BASE_API_URL}/d/patients/{patientId}/notes`,
  },
  createPatientNote: {
    method: "POST",
    url: `${BASE_API_URL}/d/patients/{patientId}/notes`,
  },
  getPatientRequests: {
    method: "GET",
    url: `${BASE_API_URL}/d/patients/{patientId}/requests`,
  },
  getPatientRequestImages: {
    method: "GET",
    url: `${BASE_API_URL}/d/patients/{patientId}/requests/images`,
  },
  getPatientImageSet: {
    method: "GET",
    url: `${BASE_API_URL}/d/patients/{patientId}/imageset`,
  },
  createPatientUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/patients/{patientId}/imageset`,
  },
  deletePatientUpload: {
    method: "POST",
    url: `${BASE_API_URL}/d/patients/{patientId}/imageset/remove`,
  },
  dentalPracticeSearch: {
    method: "POST",
    url: `${BASE_API_URL}/d/dental_practice/search`,
  },
  dentalOrganizationSearch: {
    method: "GET",
    url: `${BASE_API_URL}/d/dental_organization/search`,
  },
  dentalPatientSearch: {
    method: "POST",
    url: `${BASE_API_URL}/d/dental_patients/search`,
  },
  dentalPatientIdSearch: {
    method: "POST",
    url: `${BASE_API_URL}/d/dental_patients_ids/search`,
  },
  dentalPrescriberSearch: {
    method: "POST",
    url: `${BASE_API_URL}/d/dental_prescribers/search`,
  },
  dentalSubsidiarySearch: {
    method: "POST",
    url: `${BASE_API_URL}/d/dental_subsidiaries/search`,
  },
  getActivityLog: { method: "GET", url: `${BASE_API_URL}/d/activity` },
  updateActivityLog: {
    method: "POST",
    url: `${BASE_API_URL}/d/activity/update`,
  },
  getNewActivityLog: {
    method: "GET",
    url: `${BASE_API_URL}/d/activity?ts={ts}&op={op}`,
  },
  getNotifications: { method: "GET", url: `${BASE_API_URL}/d/requests/unread` },
  getNewNotifications: {
    method: "GET",
    url: `${BASE_API_URL}/d/requests/unread?ts={ts}`,
  },
  getTreatmentplan: {
    method: "POST",
    url: `${BASE_API_URL}/d/aline/treatmentplan/model/{requestId}`,
  },
  getRecipientDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/recipients/{recipientId}`,
  },
  getRecipientMessages: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/recipients/{recipientId}/messages`,
  },
  connectRecipientToPatient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/recipients/{recipientId}/patientconnect`,
  },
  syncRecipient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/recipients/{recipientId}/sync`,
  },
  editRecipient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/recipients/{recipientId}`,
  },
  searchRecipients: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/recipients`,
  },
  createRecipient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/recipients`,
  },
  getScheduledMessageDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/messages/{messageId}`,
  },
  editScheduledMessage: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/messages/{messageId}`,
  },
  searchScheduledMessages: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/messages`,
  },
  updateStatusScheduledMessages: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/messages/{messageId}/updatestatus`,
  },
  sendScheduledMessages: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/messages/{messageId}/send`,
  },
  createScheduledMessage: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/messages`,
  },
  searchConsultRequests: {
    method: "GET",
    url: `${BASE_API_URL}/treatmentcare/consultrequests`,
  },
  searchTreatmentPlans: {
    method: "GET",
    url: `${BASE_API_URL}/treatmentcare/treatmentplans`,
  },
  getTreatmentPlanDetails: {
    method: "GET",
    url: `${BASE_API_URL}/treatmentcare/treatmentplans/{treatmentId}`,
  },
  getTreatmentPlanFollowups: {
    method: "GET",
    url: `${BASE_API_URL}/treatmentcare/treatmentplans/{treatmentId}/followups`,
  },
  getTreatmentPlanOrders: {
    method: "GET",
    url: `${BASE_API_URL}/wooc/treatmentplan/{treatmentId}/orders`,
  },
  editTreatmentPlan: {
    method: "POST",
    url: `${BASE_API_URL}/treatmentcare/treatmentplans/{treatmentId}`,
  },
  updateStatusTreatmentPlan: {
    method: "POST",
    url: `${BASE_API_URL}/treatmentcare/treatmentplans/{treatmentId}/updatestatus`,
  },
  recipientsSearch: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/recipients/search`,
  },
  dentalClinicsScheduledMessagesSearch: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/clinics/search`,
  },
  getStatsViews: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/stats/views`,
  },
  getScheduleMessagesSelectOptions: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/select_options/`,
  },

  getMessageTemplates: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/message_templates`,
  },

  addClinicRecipient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients`,
  },
  getClinicRecipientDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients/{clinicId}`,
  },
  updateClinicRecipient: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients/{clinicId}`,
  },
  searchClinicRecipientsCollection: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients`,
  },
  updateClinicRecipientLine: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients/connect/{clinicId}`,
  },
  removeClinicRecipientLine: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/clinicrecipients/delete/{clinicRecipientId}`,
  },

  createScanTempAppointments: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_scan`,
  },
  searchScanTempAppointments: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_scan`,
  },
  getScanTempAppointmentDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_scan/{appointmentId}`,
  },
  updateScanTempAppointmentDetails: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_scan/{appointmentId}`,
  },

  updateTempAppointmentStatus: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointments/{appointmentId}/status/{action}`,
  },
  searchTempAppointments: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointments`,
  },

  createIprTempAppointments: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_ipr`,
  },
  searchIprTempAppointments: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_ipr`,
  },
  getIprTempAppointmentDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_ipr/{appointmentId}`,
  },
  updateIprTempAppointmentDetails: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/appointment_ipr/{appointmentId}`,
  },

  updateTempTreatmentPlanProgressStatus: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans/{treatmentId}/progressupdates/{action}`,
  },
  updateTempTreatmentPlanStatus: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans/{treatmentId}/status/{action}`,
  },

  createTempTreatmentPlans: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans`,
  },
  searchTempTreatmentPlans: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans`,
  },
  getTempTreatmentPlanDetails: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans/{treatmentId}`,
  },
  updateTreatmentPlanDetails: {
    method: "POST",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans/{treatmentId}`,
  },
  getOngoingTempTreatmentPlanDetailsProgress: {
    method: "GET",
    url: `${BASE_API_URL}/scheduled/line/wrappers/treatmentplans/{treatmentId}/progressupdates`,
  },
};

const ADMIN_URLS = {
  home: "/bd/admin",
  listPage: "/bd/admin/{pageName}",
  clinicListPage: "/bd/admin/clinics",
  clinicDetailsPage: "/bd/admin/clinics/{clinicId}",
  accountListPage: "/bd/admin/accounts",
  createNewAccount: "/bd/admin/newAccount",
  accountDetailsPage: "/bd/admin/accounts/{accountId}",
};

const ADMIN_API_URLS = {
  getSelectOptions: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/select_options/`,
  },
  getERPWholesalers: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/erp/wholesalers`,
  },
  getUserRoles: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/accounts/roles`,
  },
  createNewCalendar: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/calendars`,
  },
  getCalendarsList: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/calendars`,
  },
  getCalendarsDetails: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/calendars/{calendarId}`,
  },
  updateCalendarDetails: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/calendars/{id}`,
  },
  getPracticesList: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/clinics`,
  },
  getPracticeDetails: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/clinics/{clinicId}`,
  },
  updatePracticeDetails: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/clinics/{clinicId}`,
  },
  getPracticeAccounts: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/clinics/{clinicId}/accounts`,
  },
  createPracticeAccounts: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/clinics/{clinicId}/accounts`,
  },
  updatePracticeAccounts: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/clinics/{clinicId}/accounts/{accountId}`,
  },
  getAccountsList: {
    method: "GET",
    ufrl: `${BASE_API_URL}/adminclinic/accounts`,
  },
  getAccountDetails: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/accounts/{accountId}`,
  },
  updateAccountDetails: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/accounts/{accountId}`,
  },
  dentalClinicSearch: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/clinics/search`,
  },
  dentalOrganizationSearch: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/organizations/search`,
  },
  createNewAccount: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/accounts`,
  },
  fetchProductCollection: {
    method: "GET",
    url: `${BASE_API_URL}/adminclinic/products/{country}/{product}`,
  },
  fetchBillingUrl: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/billing`,
  },
  getResetUrl: {
    method: "POST",
    url: `${BASE_API_URL}/adminclinic/accounts/reset_url`,
  },
};

const AGE_RANGE = [
  { value: "1995", label: "1995-2000" },
  { value: "1990", label: "1990-1995" },
  { value: "1985", label: "1985-1990" },
  { value: "1980", label: "1980-1985" },
  { value: "1975", label: "1975-1980" },
  { value: "1970", label: "1970-1975" },
  { value: "1965", label: "1965-1970" },
  { value: "1960", label: "1960-1965" },
];

const REQUIRED_IMAGES = [
  /* TODO: add required images
  'impression_top',
  'impression_bottom',
  'internal_occlusal_top',
  'internal_occlusal_bottom',
  'external_occlusal_front'*/
];

const requestImageFields = [
  [
    { imageKey: "impression_top", imageTypes: ".stl" },
    { imageKey: "impression_bottom", imageTypes: ".stl" },
    { imageKey: "panorama", imageTypes: null },
    { imageKey: "ceph", imageTypes: null },
    { imageKey: "ct", imageTypes: ".zip" },
  ],
  [
    {
      imageKey: "face_front",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "face_smile",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "face_right",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "face_left",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "face_angle",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
  ],
  [
    {
      imageKey: "internal_occlusal_top",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "internal_occlusal_bottom",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "external_occlusal_front",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "external_occlusal_right",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
    {
      imageKey: "external_occlusal_left",
      imageTypes: "image/jpeg,image/png,image/gif, image/jfif",
    },
  ],
];

const requestPhotoAssessmentImageFieldList = [
  "internal_occlusal_top",
  "internal_occlusal_bottom",
  "external_occlusal_front",
  "external_occlusal_right",
  "external_occlusal_left",
];

const requestImageFieldList = [
  "impression_top",
  "impression_bottom",
  "panorama",
  "ceph",
  "ct",
  "face_front",
  "face_smile",
  "face_right",
  "face_left",
  "face_angle",
  "internal_occlusal_top",
  "internal_occlusal_bottom",
  "external_occlusal_front",
  "external_occlusal_right",
  "external_occlusal_left",
];

// TODO: this is temporary, no required images
const initState_formRequiredImages = {};
/*
const initState_formRequiredImages = {
  impression_top: false,
  impression_bottom: false,
  internal_occlusal_top: false,
  internal_occlusal_bottom: false,
  external_occlusal_front: false
  // next two are only for when there is a bite problem (so not required)
  //'external_occlusal_right',
  //'external_occlusal_left'
};*/

const initState_imageForm = {
  impression_top: false,
  impression_bottom: false,
  panorama: false,
  ceph: false,
  ct: false,
  face_front: false,
  face_smile: false,
  face_right: false,
  face_left: false,
  face_angle: false,
  internal_occlusal_top: false,
  internal_occlusal_bottom: false,
  external_occlusal_front: false,
  external_occlusal_right: false,
  external_occlusal_left: false,
};

const REGEX_CHINESE =
  /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

// these return key for intl.messages to find
const required = (value) => (value ? undefined : "required");
const mustBeNumber = (value) => (isNaN(value) ? "must_be_number" : undefined);
const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const noChineseCharacters = (str) =>
  REGEX_CHINESE.test(str) ? "no_chinese_characters" : undefined;
const mustBeEmail = (email) => {
  if (email) {
    let matched = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (matched) {
      return undefined;
    } else {
      return "email_not_valid";
    }
  }
  return undefined;
};
export {
  BASE_API_URL,
  BASE_URL,
  URLS,
  ADMIN_URLS,
  APPOINTMENTTYPES_COLOR_SCHEME,
  REMINDER_TIME,
  GOOGLE_CLIENT_ID,
  TOKBOX_KEY,
  PAGE_SIZE,
  AGE_RANGE,
  AVAILABLE_COUNTRIES,
  AVAILABLE_CLINIC_TYPES,
  NOTIFICATION_POLLING_INTERVAL,
  NOTIFICATION_POLLING_HISTORYLENGTH,
  GENDERS,
  JAW_CHOICES,
  STATUS_CHOICES,
  AVAILABLE_LANGUAGES,
  API_URLS,
  ADMIN_API_URLS,
  REQUIRED_IMAGES,
  MOMENT_DATE_FORMAT,
  MOMENT_LONG_DATE_FORMAT,
  MAIN_SCROLL_CSS_CLASS_NAME,
  API_DATE_FORMAT,
  API_DATETIME_FORMAT,
  requestImageFields,
  requestImageFieldList,
  initState_formRequiredImages,
  initState_imageForm,
  requestPhotoAssessmentImageFieldList,
  composeValidators,
  required,
  mustBeNumber,
  minValue,
  mustBeEmail,
  noChineseCharacters,
};
