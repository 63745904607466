import React, { useEffect } from 'react';
import { usePubNub } from 'pubnub-react';
import { useAuth } from 'js/_libs/auth';
import storage from 'js/_helpers/storage';
import { GetPNChannelsQuery } from './api/usePNChannelsQuery';
import { useNotificationStore } from './store';

import { updateAppointmentData } from 'js/Appointments/api/useClinicDashboardAppointmentsQuery';
import { refetchAppointmentRequestCount } from 'js/Appointments/api/useAppointmentRequestCount';
import { refetchAppointmentRequests } from 'js/Appointments/api/useAppointmentRequestsQuery';

/**
 * Component for pubnub notifications.
 * Receives messages from pubnub and sends to notification store
 * Receives messages from pubnub and updates appointment data
 * In Notifications for now because it only gets clinic notifications for appointment
 * 
 */
export const PubnubContainer = ({cipher, userId}) => {
    // get channels from db that user is subscribed to (notification channel only currently)
    const pubnub = usePubNub();
    const queryResults = GetPNChannelsQuery({})

    /**
     *  event = {
            actualChannel: null
            channel: "clinic_notifications.e1debbb7-8fa3-4922-94f2-e048fb6b70a2"
            message: data: "b'7443127312218432\\x03\\x81e\\x80\\x1c\\x00\\x9a\\xd3\\x81\\xa8\\xdftX\\xa9\\x19\\xfdX\\xbc8\\x82L\\xa9\\x19\\xb9\\x1eF\\x84w\\xdf/oI0\\x07x\\x86b\\xc1~\\xaag\\x1a\\xb2\\xd1\\xd6\\xd1\\xce\\xe4\\x8f\\xc7P\\xf6\\xbb\\x1a\\x97\\x86?\\x18I\\xf6N\\x12^\\x85\\xe4z\\x837x\\xfai\\x81V\\xed\\xf4;\\xdb:\\xe7I\\x90\\xdf.\\xfd\\xa4\\xa8\\x96\\xfd9\\x8d\\xc75\\x03\\xca\\x92\"U\\xe8<U%\\x19\\xe2\\xfe\\xbdE\\x11~\\xba4;\\x90\\x97/>XC\\x8a\\x0c\\x8bi\\xe5\\xe5]\\xab\\xf5\\xe8\\xa5\\xc5\\xe0\\'\\xb8&\\xa5\\x11\\x18&\\xdf\\x16\\x15Y\\x08F\\x92'"
            pn_gcm: {notification: {…}, data: {…}}
            publisher: "beforedent-system-api"
            subscribedChannel: "clinic_notifications.e1debbb7-8fa3-4922-94f2-e048fb6b70a2"
            subscription: undefined
            timetoken: "16927954173552377"
            userMetadata: {type: 'APPOINTMENT'}
        }
     */

    useEffect(() => {
        // function to handle new message received in pubnub
        const handleMessage = async event => {
            let { channel, timetoken, publisher, message } = event;
            let { data: msgData, pn_gcm } = message;
            let { data, notification } = pn_gcm;
            let { title, body, click_action } = notification;
            //TODO: check channel startswith `clinic_notifications.${userId}`
            // decrypt message cause i encrypted it in backend
            let decryptedData = await pubnub.decrypt(msgData, cipher)
            if (decryptedData?.object_type === "appointment"){
                // add notification and update appointment?
                if (decryptedData?.type === "status_update"){
                    // send to notification
                    useNotificationStore.getState().addNotification(
                        {...decryptedData?.data, type: 'appointment_status_update'}
                    )
                    // HACK: shoudl not call this function from here? very far away in different component
                    updateAppointmentData(decryptedData?.object_id, {...decryptedData?.data, animate: true})

                }
            } else if (decryptedData?.object_type === "appointment_request"){
                if (decryptedData?.type === "created"){
                    // send to notification
                    useNotificationStore.getState().addNotification(
                        {...decryptedData?.data, type: 'appointment_request_created'}
                    )
                    refetchAppointmentRequestCount()
                    refetchAppointmentRequests()
                    // HACK: shoudl not call this function from here? very far away in different component
                    //updateAppointmentData(decryptedData?.object_id, {...decryptedData?.data, animate: true})

                }
            }
        }
        // add function to listener
        const listenerParams = { message: handleMessage }

        // set userId for pubnub when user is logged in
        if (userId ){
            pubnub.addListener(listenerParams);
            pubnub.setUserId(userId);
            pubnub.setAuthKey(storage.getToken())
            pubnub.subscribe({channels: queryResults?.data?.channels});
        }    
        // cleanup
        return () => {
            pubnub.unsubscribe({ channels: queryResults?.data?.channels })
            pubnub.removeListener(listenerParams)
        }
    }, [queryResults?.data?.channels, pubnub, userId, cipher]);

    return (<></>)

}

export const PubnubWrapper = () => {
    const { user } = useAuth();

    if (user?.user?.unique_id){
        return (
            <PubnubContainer cipher={user?.user?.cipher} userId={user?.user?.unique_id} />
        )
    } else {
        return (<></>)
    }
}