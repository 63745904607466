import { Icon, Image } from "@chakra-ui/react";
import logo from 'images/logoPloverScheduling.png';



const LogoImage = (props) => {
  const iconWidth = `${props.width || 167}px`;
  const iconHeight = `${props.width || 45}px`;
  return (<Image src={logo} alt="logo" height={iconHeight} width={iconWidth} />);
}

const Logo = (props) => {
  const iconWidth = `${props.width || 167}px`;
  const iconHeight = `${props.width || 45}px`;
  const iconViewBox = `0 0 ${props.width || 167} ${props.width || 45}`;
  return (
    <Icon
      width={iconWidth}
      height={iconHeight}
      viewBox={iconViewBox}
      color={"white"}
      {...props}
    >
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox={iconViewBox}
        fill="none"
      >
        <path
          d="M36.4299 24.3201C33.7399 32.0401 25.7799 38.7001 21.3499 41.2601H21.1099V31.7201H25.5899C30.4599 31.7201 34.6499 28.6701 36.4299 24.3201Z"
          fill="#2A2B2B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.5901 6.68994H5.97011C4.67011 6.68994 3.61011 7.77994 3.61011 9.10994V38.9799C3.61011 40.3199 4.67011 41.3999 5.97011 41.3999H21.1101V31.7199H25.5901C30.4601 31.7199 34.6501 28.6699 36.4301 24.3199C36.4501 24.2899 36.4601 24.2499 36.4701 24.2099C37.0501 22.7899 37.3601 21.2399 37.3601 19.6099V18.7999C37.3601 12.1099 32.0901 6.68994 25.5901 6.68994ZM13.2601 7.81994C14.0401 7.81994 14.6801 8.46994 14.6801 9.26994C14.6801 10.0699 14.0401 10.7299 13.2601 10.7299C12.4801 10.7299 11.8501 10.0799 11.8501 9.26994C11.8501 8.45994 12.4801 7.81994 13.2601 7.81994ZM24.3301 10.7299C23.5501 10.7299 22.9201 10.0799 22.9201 9.26994C22.9201 8.45994 23.5501 7.81994 24.3301 7.81994C25.1101 7.81994 25.7501 8.46994 25.7501 9.26994C25.7501 10.0699 25.1101 10.7299 24.3301 10.7299Z"
          fill="#1CCDB7"
        />
        <path
          d="M16.1699 6.6899C16.1699 6.0099 15.9599 5.3399 15.5699 4.7899C15.1799 4.2399 14.6299 3.8299 13.9999 3.6199C13.3699 3.4099 12.6899 3.4099 12.0599 3.6199C11.4299 3.8299 10.8799 4.2399 10.4899 4.7899C10.0999 5.3399 9.88989 6.0099 9.88989 6.6899C9.88989 7.3699 10.0999 8.0399 10.4899 8.5899C10.8799 9.1399 11.4299 9.5499 12.0599 9.7599C12.4199 9.8799 12.7999 9.9299 13.1799 9.9099C13.6399 9.8899 13.8899 9.3999 13.7499 8.9599C13.6099 8.5099 13.0899 8.2799 12.6399 8.1499C12.6199 8.1499 12.5999 8.1399 12.5799 8.1299C12.2799 8.0299 12.0199 7.8399 11.8399 7.5799C11.6599 7.3199 11.5599 7.0099 11.5599 6.6899C11.5599 6.3699 11.6599 6.0599 11.8399 5.7999C12.0199 5.5399 12.2799 5.3499 12.5799 5.2499C12.8799 5.1499 13.1999 5.1499 13.4899 5.2499C13.7899 5.3499 14.0499 5.5399 14.2299 5.7999C14.4099 6.0599 14.5099 6.3699 14.5099 6.6899H16.1699Z"
          fill="#2A2B2B"
        />
        <path
          d="M27.2302 6.8099C27.2302 6.1299 27.0202 5.3399 26.6302 4.7899C26.2402 4.2399 25.6902 3.8299 25.0602 3.6199C24.4302 3.4099 23.7502 3.4099 23.1202 3.6199C22.4902 3.8299 21.9402 4.2399 21.5502 4.7899C21.1602 5.3399 20.9502 6.0099 20.9502 6.6899C20.9502 7.3699 21.1602 8.0399 21.5502 8.5899C21.9402 9.1399 22.4902 9.5499 23.1202 9.7599C23.4802 9.8799 23.8602 9.9299 24.2402 9.9099C24.7002 9.8899 24.9502 9.3999 24.8102 8.9599C24.6702 8.5099 24.1502 8.2799 23.7002 8.1499C23.6802 8.1499 23.6602 8.1399 23.6402 8.1299C23.3402 8.0299 23.0802 7.8399 22.9002 7.5799C22.7202 7.3199 22.6202 7.0099 22.6202 6.6899C22.6202 6.3699 22.7202 6.0599 22.9002 5.7999C23.0802 5.5399 23.3402 5.3499 23.6402 5.2499C23.9402 5.1499 24.2602 5.1499 24.5502 5.2499C24.8502 5.3499 25.1102 5.5399 25.2902 5.7999C25.4702 6.0599 25.5702 6.3699 25.5702 6.6899L27.2302 6.8099Z"
          fill="#2A2B2B"
        />
        <path
          d="M25.7098 24.0499C28.0294 24.0499 29.9098 22.1158 29.9098 19.7299C29.9098 17.344 28.0294 15.4099 25.7098 15.4099C23.3902 15.4099 21.5098 17.344 21.5098 19.7299C21.5098 22.1158 23.3902 24.0499 25.7098 24.0499Z"
          fill="#2A2B2B"
        />
        <path
          d="M56.1 25.45H56.6V18.44H60.81C62.63 18.44 64.1 17.78 65.11 16.7C66.11 15.63 66.64 14.17 66.64 12.62C66.64 11.07 66.13 9.61 65.12 8.54C64.11 7.46 62.65 6.8 60.81 6.8H53.48V25.45H56.09H56.1ZM82.49 25.45H82.99V22.49H75.09V6.79H71.98V25.44H82.49V25.45ZM118.79 25.45H119.13L119.25 25.13L126.08 7.48L126.34 6.8H122.84L122.72 7.12L117.46 21.12L112.2 7.12L112.08 6.8H108.58L108.84 7.48L115.69 25.13L115.81 25.45H118.78H118.79ZM142.79 25.45H143.29V22.49H134.27V17.4H143.11V14.44H134.27V9.75H143.29V6.79H131.16V25.44H142.79V25.45ZM162.31 25.45H163.22L162.73 24.68L158.55 18.12C159.53 17.89 160.45 17.4 161.17 16.64C162.08 15.68 162.66 14.32 162.66 12.62C162.66 10.92 162.06 9.41 161 8.38C159.95 7.35 158.48 6.8 156.81 6.8H149.48V25.45H152.6V18.44H155.23L159.39 25.22L159.54 25.46H162.32L162.31 25.45ZM63.44 12.61C63.44 13.48 63.14 14.19 62.65 14.68C62.16 15.17 61.44 15.47 60.55 15.47H56.59V9.75H60.55C61.44 9.75 62.15 10.05 62.65 10.54C63.14 11.03 63.44 11.74 63.44 12.61ZM87.08 16.13C87.08 21.57 90.67 25.76 96.01 25.76C101.35 25.76 104.97 21.57 104.97 16.13C104.97 10.69 101.35 6.5 96.01 6.5C90.67 6.5 87.08 10.69 87.08 16.13ZM101.78 16.13C101.78 18.08 101.19 19.76 100.19 20.93C99.19 22.1 97.77 22.8 96.01 22.8C94.25 22.8 92.82 22.1 91.84 20.93C90.85 19.76 90.27 18.08 90.27 16.13C90.27 14.18 90.85 12.49 91.84 11.32C92.83 10.16 94.24 9.46 96.01 9.46C97.78 9.46 99.19 10.16 100.19 11.32C101.19 12.49 101.78 14.16 101.78 16.13ZM159.46 12.61C159.46 13.48 159.16 14.2 158.66 14.69C158.16 15.19 157.44 15.49 156.55 15.49H152.59V9.74H156.55C157.44 9.74 158.16 10.04 158.66 10.52C159.15 11.01 159.46 11.71 159.46 12.6V12.61Z"
          fill="#2A2B2B"
        />
        <path
          d="M56.1499 40.29C54.6899 40.29 53.5999 39.74 52.8999 38.97L53.5299 38.1699C54.0799 38.7899 54.9899 39.36 56.1799 39.36C57.6899 39.36 58.1699 38.5599 58.1699 37.9199C58.1699 35.7799 53.1599 36.9699 53.1599 34.0099C53.1599 32.6399 54.3999 31.7 56.0799 31.7C57.3599 31.7 58.3699 32.1399 59.0799 32.8699L58.4499 33.63C57.8199 32.93 56.9299 32.6399 55.9999 32.6399C54.9999 32.6399 54.2599 33.1699 54.2599 33.9599C54.2599 35.8299 59.2699 34.7599 59.2699 37.8499C59.2699 39.0399 58.4299 40.29 56.1499 40.29Z"
          fill="#2A2B2B"
        />
        <path
          d="M68.6698 40.29C66.2298 40.29 64.3198 38.55 64.3198 36C64.3198 33.45 66.2398 31.71 68.6698 31.71C70.1698 31.71 71.2098 32.41 71.8798 33.35L70.9998 33.84C70.5198 33.13 69.6298 32.63 68.6598 32.63C66.8098 32.63 65.3998 34.01 65.3998 36C65.3998 37.99 66.8098 39.37 68.6598 39.37C69.6198 39.37 70.5098 38.86 70.9998 38.16L71.8898 38.63C71.1798 39.59 70.1598 40.28 68.6698 40.28V40.29Z"
          fill="#2A2B2B"
        />
        <path
          d="M84.0998 40.1401H83.0498V36.3301H78.0998V40.1401H77.0498V31.8401H78.0998V35.4101H83.0498V31.8401H84.0998V40.1401Z"
          fill="#2A2B2B"
        />
        <path
          d="M95.4401 40.1401H89.8901V31.8401H95.4401V32.7601H90.9501V35.4301H95.3601V36.3501H90.9501V39.2101H95.4401V40.1301V40.1401Z"
          fill="#2A2B2B"
        />
        <path
          d="M103.82 40.1401H100.93V31.8401H103.82C106.46 31.8401 108.16 33.6401 108.16 35.9901C108.16 38.3401 106.46 40.1301 103.82 40.1301V40.1401ZM103.82 39.2201C105.88 39.2201 107.07 37.7801 107.07 36.0001C107.07 34.2201 105.92 32.7701 103.82 32.7701H101.98V39.2201H103.82Z"
          fill="#2A2B2B"
        />
        <path
          d="M117.08 40.2901C114.79 40.2901 113.63 38.9601 113.63 36.9201V31.8501H114.7V36.9001C114.7 38.4201 115.53 39.3701 117.09 39.3701C118.65 39.3701 119.49 38.4101 119.49 36.9001V31.8501H120.56V36.9101C120.56 38.9701 119.39 40.2901 117.09 40.2901H117.08Z"
          fill="#2A2B2B"
        />
        <path
          d="M131.19 40.1401H126.2V31.8401H127.25V39.2201H131.18V40.1401H131.19Z"
          fill="#2A2B2B"
        />
        <path
          d="M137.46 40.1401H136.41V31.8401H137.46V40.1401Z"
          fill="#2A2B2B"
        />
        <path
          d="M150.26 40.1401H149.24L144.3 33.5201V40.1401H143.25V31.8401H144.33L149.21 38.3201V31.8401H150.26V40.1401Z"
          fill="#2A2B2B"
        />
        <path
          d="M160.06 40.3C157.65 40.3 155.71 38.57 155.71 36C155.71 33.43 157.65 31.71 160.06 31.71C161.57 31.71 162.61 32.36 163.35 33.25L162.51 33.77C161.98 33.1 161.08 32.63 160.06 32.63C158.21 32.63 156.8 34.01 156.8 36C156.8 37.99 158.21 39.37 160.06 39.37C161.08 39.37 161.91 38.9 162.35 38.47V36.79H159.43V35.87H163.39V38.86C162.6 39.73 161.45 40.3 160.06 40.3Z"
          fill="#2A2B2B"
        />
      </svg>
    </Icon>
  );
};
export default Logo;
